<template>  
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />
    <div class="content">
      <template>
        <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="$router.push({ name: 'RanapPasienPerawatan' })" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
          
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg-white">
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="card-title font-weight-semibold">Tindakan ICD 9</h6>
                  <div class="form-group mb-0">
                    <button @click="apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                      class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
                  </div>                  
                </div>
              </div>
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Tindakan Medis</th>
                    <th>Diagnosa</th>
                    <th>Tanggal</th>
                    <th>Dokter Penanggung Jawab</th>
                    <th>Inform Consent</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in (tindakanICD9||[])" :key="k">
                    <td>{{k+1}}</td>
                    <td>{{v.aranic_name||"-"}}</td>
                    <td>
                      <a href="javascript:;" @click="lihatDiagnosa(v,k)" v-b-tooltip.click.html="v.diagnosa||'...'"
                      class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                    </td>
                    <td>
                      <span v-if="v.aranic_updated_date">{{v.aranic_updated_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                      <span v-else>{{v.aranic_created_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                    </td>
                    <td>
                      {{v.nama_pembuat||"-"}}
                    </td>
                    <td>
                      <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.aranic_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                        <i class="icon-file-eye2"></i>
                      </a>
                    </td>
                    <td>
                      <span v-if="v.aranic_status == 'DONE'" class="badge badge-success">Selesai</span>
                      <span v-else class="badge badge-info">Unggah TTD</span>
                    </td>
                    <td class="text-center">
                      <!--
                      <a href="javascript:;" @click="openTemplateIC(v)" v-b-tooltip.hover title="Unduh Template Dokumen" class="btn btn-sm btn-icon alpha-warning text-warning-800 border-warning mb-1"><i class="icon-download"></i></a>
                      -->
                      
                      <a href="javascript:;" @click="openTemplateICUpload(v,k)" v-b-tooltip.hover title="Unggah File TTD" class="btn btn-sm btn-icon alpha-info text-info-800 border-info mb-1"><i class="icon-file-text2"></i></a>
                      
                      <a :href="v.aranic_status == 'DONE' && uploader(v.aranic_file)" target="_blank" v-if="v.aranic_file" v-b-tooltip.hover title="Unduh TTD Dokumen" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mb-1"><i class="icon-download"></i></a>
                    </td>
                  </tr>
                  <tr v-if="!(tindakanICD9||[]).length">
                    <td class="text-center" colspan="99">Tidak ada data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>  
      </template>
    </div>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
              {{rowIC.aranic_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
              {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
              {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
              {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
              {{rowIC.aranic_tata_cara||"-"}}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
              {{rowIC.aranic_tujuan||"-"}}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
              {{rowIC.aranic_risiko||"-"}}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
              {{rowIC.aranic_kompilasi||"-"}}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
              {{rowIC.aranic_prognosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
              {{rowIC.aranic_alternatif||"-"}}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
              {{rowIC.aranic_lainnya||"-"}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
              </div>
            </td>
            <td width="50%">
              <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-modal>

    <validation-observer ref="VFormModal">
    <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template" title="Unduh Template Dokumen" size="lg">
        <div class="col-md-12 col-12 mb-2">
          <b-form-group class="mb-0">
            <label>Dokter Pelaksana</label>
            <v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="rowIC.dokter_pelaksana"
              :options="mDPJP" label="text" :reduce="v=>v.text"></v-select>
          </b-form-group>
        </div>

        <table class="table table-bordered table-sm text-uppercase">
            <tbody>
                <tr>
                    <td colspan="3" class="bg-primary">Data Pasien</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{patientData.ap_fullname||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Usia</h4>
                        <p>{{patientData.ap_usia_with_ket||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat</h4>
                        <p>{{patientData.ap_address||"-"}}</p>
                        </div>
                    </td>
                </tr>
                <!-- == 'PJ'-->
                <template v-if="rowIC.aranic_persetujuan_tindakan == 'PJ'">
                <tr>
                    <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                        placeholder="Nama Penanggung Jawab"/>
                        <VValidate 
                            name="Nama Penanggung Jawab" 
                            v-model="rowIC.aranic_nama_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Usia Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung" class="form-control"
                        placeholder="Usia Penanggung Jawab"/>
                        <VValidate 
                            name="Usia Penanggung Jawab" 
                            v-model="rowIC.aranic_usia_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_address_pj" type="text" name="alamat" id="alamat" class="form-control"
                        placeholder="Alamat Penanggung Jawab"/>
                        <VValidate 
                            name="Alamat Penanggung Jawab" 
                            v-model="rowIC.aranic_address_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
    </b-modal>
    </validation-observer>

    <validation-observer ref="VFormUpload">
      <b-modal v-model="openTemplateICModalUpload" @ok.prevent="submitDok(rowIC,rowIC.k)" ok-title="Submit" title="Unduh Template Dokumen" size="sm">
        <div class="row">
          <div class="col-md-12">
            <a href="javascript:;" @click="openTemplateIC(rowIC)" class="btn alpha-success border-success text-success-800 mb-1">
                <b><i class="icon-download"></i></b>
                <span class="ml-2">Unduh Template Dokumen</span>
            </a>
            <Uploader v-model="rowIC.aranic_file" isDocument type="doc_only" />

            <VValidate 
              name="Dokumen" 
              v-model="rowIC.aranic_file" 
              :rules="{required: 1}"
            />
          </div>
        </div>
      </b-modal>
    </validation-observer>
    

  </div>          
</template>



<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  const moment = require('moment')

  export default {
    extends: GlobalVue,
    components:{PatientInfo},
    data() {
      return {
        patientData: {},
        loading: {
          patientInfo: false,
        },
        tindakanICD9: [],
        openModalICDetail: false,
        openTemplateICModal: false,
        openTemplateICModalUpload: false,

        mDPJP: [],
        rowIC: {},
      }
    },
    
    methods: {
      toDate(dates){
        return moment(dates).format('DD MMMM YYYY')
      },
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },
      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      lihatDiagnosa(v,k){
        Gen.apiRest(
            "/do/"+ this.modulePage,
            {data:{type:'lihat-diagnosa', id: v.cppt_id}}, 
            "POST"
        ).then(res=>{
          this.tindakanICD9[k]['diagnosa'] = res.data        
        })
      },
      
      openInformConsentDetail(v) {
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
            type: 'get-data-ic',
            id: v.aranic_id,
          }
        }, 'POST').then(res => {
          this.rowIC = res.data.row
          this.rowIC.mi9_name = v.mi9_name
          this.rowIC.mi9_code = v.mi9_code
          this.openModalICDetail = true
        })
      },
      openTemplateIC(v){
          this.rowIC = v
          this.rowIC.aranic_nama_pj = this.rowIC.aranic_nama_pj||this.patientData.aranres_pj_nama
          this.rowIC.aranic_address_pj = this.rowIC.aranic_address_pj||this.patientData.aranres_pj_alamat
          this.rowIC.aranic_usia_pj = this.rowIC.aranic_usia_pj||this.patientData.ap_usia_with_ket
          this.openTemplateICModal = true
          this.$set(this.rowIC, 'dokter_pelaksana', this.rowIC.dokter_pelaksana||this.patientData.bu_full_name)
      },
      
      openTemplateICUpload(v,k){
          this.rowIC = v
          this.rowIC.k = k
          this.rowIC.aranic_nama_pj = this.rowIC.aranic_nama_pj||this.patientData.aranres_pj_nama
          this.rowIC.aranic_address_pj = this.rowIC.aranic_address_pj||this.patientData.aranres_pj_alamat
          this.rowIC.aranic_usia_pj = this.rowIC.aranic_usia_pj||this.patientData.ap_usia_with_ket
          this.openTemplateICModalUpload = true
      },
      submitDok(v,k){
        this.$refs['VFormUpload'].validate().then(async success => {
          if(success){
            await this.uploadDok(v,k)
            this.openTemplateICModalUpload = false
            this.$swal.fire({
              title: "Inform Consent Berhasil Diunggah",
              icon: "success",              
            })
          }
        })
      },
      uploadDok(v,k=''){
          Gen.apiRest('/do/' + this.modulePage, {
              data: {
                  type: 'update-data-ic',
                  id: v.aranic_id,
                  aranic_file: v.aranic_file,
                  aranic_nama_pj: v.aranic_nama_pj,
                  aranic_usia_pj: v.aranic_usia_pj,
                  aranic_address_pj: v.aranic_address_pj,
              }
          }, 'POST').then(res => {
              if(k){
                  this.tindakanICD9[k]['aranic_status'] = 'DONE'
                  this.openTemplateICModalUpload = false 
              }
          })
      },
      unduhtemplateIC(v){
        this.$refs['VFormModal'].validate().then(success => {
          if(success){
              this.uploadDok(v)                
              setTimeout(()=>{
                  this.openTemplateICModal = false
                  let data = {exptype: 'xlsx', type: "export", id: v.aranic_id, regID: v.aranic_aranr_id, dokter_pelaksana: v.dokter_pelaksana}
                  let self = this
                  self.loadingOverlay = true
                  $.ajax({
                      type: "POST",
                      url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRanap'}?token=IXs1029102asoaksoas102901290`,
                      data: data,
                      cache: false,
                      xhrFields:{
                          responseType: 'blob'
                      },
                      success: data => 
                      {
                          self.loadingOverlay = false
                          var link = document.createElement('a')
                          link.href = window.URL.createObjectURL(data)
                          link.download = `${'Inform-consent'}-${this.patientData.ap_fullname}-${moment().format("YYYY-MM-DD")}-${this.patientData.aranr_reg_code}.pdf`
                          link.click()
                      },
                      fail: data => {
                          self.loadingOverlay = false
                          alert('Not downloaded')
                      }
                  })
              },2000)
              
          }
        })
      },
    },

    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
  }
</script>